import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContinuarComprandoComponent } from 'src/app/pages/cart/continuar-comprando/continuar-comprando.component';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { EcommerceService } from 'src/app/services/ecommerce/ecommerce.service';

@Component({
  selector: 'app-add-to-cart',
  templateUrl: './controls2-component.component.html',
  styleUrls: ['./controls2-component.component.scss'],
})
export class Controls2ComponentComponent {
  @Input() storedPromotions: any[] = [];
  products: any[] = [];
  aditional: any[] = [];
  aditionalSelected: any[] = [];
  note: string = '';
  private _user: any = null;
  quantity: number = 1;
  cartItemCount: number = 0;
  isPromotion =true;
  constructor(
    private ecommerceService: EcommerceService,
    private snackBar: MatSnackBar,
    private authService: AuthenticationService,
    private router: Router,
    private modalService: NgbModal
  ) {
    this.authService.currentUser.subscribe((res) => {
      this._user = res ? res.user : null;
    });
  }
  ngOnInit() {
    this.extractPromotionData();
    if (!this.aditionalSelected) {
      this.aditionalSelected = this.products.map(() => ({ selected: [] }));
    }
  }
  extractPromotionData(): void{
    this.products = [];
    this.aditional = [];
    this.aditionalSelected = [];
    this.note = '';

    this.storedPromotions.forEach((promotion) => {
      if (promotion.groupCode && promotion.products) {
        // Caso "rewards"
        promotion.products.forEach((item: any) => {
          this.products.push(item.product);
          this.aditional.push(item.aditional);
          this.aditionalSelected.push(item.aditionalSelected);
          this.note = item.notas || '';
        });
      } else if (promotion.offersCode && promotion.products) {
        // Caso "offers"
        Object.values(promotion.products).forEach((item: any) => {
          this.products.push(item.product);
          this.aditional.push(item.aditional);
          this.aditionalSelected.push(item.aditionalSelected);
          this.note = item.notas || '';
        });
      }
    });

    console.log('Products:', this.products);
    console.log('Aditional:', this.aditional);
    console.log('AditionalSelected:', this.aditionalSelected);
    console.log('Note:', this.note);
  }

  public async addPromotionToCart() {
    if (!this._user) {
      this.snackBar.open('Por favor, inicie sesión para agregar productos al carrito', '×', {
        panelClass: 'error',
        verticalPosition: 'top',
        duration: 5000,
      });
      setTimeout(() => {
        this.router.navigate(['/auth/ingresar']);
      }, 2000);
      return;
    }

    for (const promotion of this.storedPromotions) {
      const isGroupCode = !!promotion.groupCode;
      const promotionProducts = isGroupCode
        ? promotion.products // Solo un producto para groupCode.
        : Object.values(promotion.products); // Múltiples productos para offersCode.

        for (const promoProduct of promotionProducts) {
        console.log("test: ",promoProduct )
        const { aditional, aditionalSelected, notas, product } = promoProduct;
        console.log("test: ",aditionalSelected )
        // Validar adicionales seleccionados.
        if (
          aditional &&
          this.onVerifiedQuantitySubnivel(aditionalSelected)
        ) {
          this.snackBar.open(
            'Por favor, completa las selecciones requeridas para la promoción',
            '×',
            { panelClass: 'error', verticalPosition: 'top', duration: 5000 }
          );
          return;
        }

        // Procesar adicionales seleccionados.
        const det = aditional
          ? aditionalSelected._selected.slice()
          : [];
          console.log("JE: ", det);
        const additionalDetail = [];
        for (const mat of det) {
          if (mat.material.type == null) {
            additionalDetail.push(mat);
          } else {
            for (const mad of mat.aditionalSelected._selected) {
              additionalDetail.push(mad);
            }
          }
        }
        console.log("JE 1: ", additionalDetail);
        // Crear objeto auxiliar para el producto promocional.
        const auxProduct = {
          ...product,
          note: notas || '',
          cartCount: this.quantity,
        };

        if (aditional) {
          auxProduct.aditional.aditionalDetail = this.parseAdditional(
            aditional,
            additionalDetail
          );
          auxProduct.newPrice = this.getTotalItemSelected(product, aditionalSelected);
        }

        // Agregar el producto promocional al carrito.
        this.ecommerceService.addToCart(auxProduct);
        console.log('Producto promocional agregado al carrito:', auxProduct);
        this.cartItemCount += this.quantity;
      }
    }

    // Resetear cantidad y mostrar modal de confirmación.
    this.quantity = 1;
    const modalRef = this.modalService.open(ContinuarComprandoComponent);
    modalRef.componentInstance.itemCount = this.cartItemCount;
  }

  // Adaptar la función getTotalItemSelected para promociones.
  private getTotalItemSelected(product: any, aditionalSelected: any) {
    let total = Number(product.newPrice || 0);

    for (const item of aditionalSelected._selected) {
      if (item.material.type === 'A') {
        for (const element of item.aditionalSelected._selected) {
          if (element.isAditionalPrice === '1') {
            total += Number(element.price) * element.quantity;
          }
        }
      }
    }
    return total;
  }

  // Validar adicionales seleccionados.
  private onVerifiedQuantitySubnivel(aditionalSelected: any) {
    console.log("EXA 4: ", aditionalSelected)
    let found = false;
    for (let item of aditionalSelected._selected) {
      if (item.material.type === 'A' && item.material.aditional.quantity > 0) {
        let sum = 0;
        console.log("EXA 5: ", item)
        for (let i of item.aditionalSelected._selected) {
          sum += i.quantity;
        }
        // if (
        //   sum < item.material.aditional.minQuantity ||
        //   sum > item.material.aditional.quantity
        // ) {
        //   found = true;
        //   break;
        // }
        if (!(sum >= item.material.aditional.minQuantity && sum <= item.material.aditional.quantity)) {
          found = true
          break
        }
      }
    }
    return found;
  }

  // Procesar adicionales seleccionados.
  private parseAdditional(aditional: any, additionalDetail: any) {
    return aditional.aditionalDetail.map((item: any) => {
      const hasDetails = additionalDetail.some((detail: any) => detail.id === item.id);
      item.hasBeenAdded = !!hasDetails;
      item.isActiveToAddInApp = null;

      if (item.material.type === 'A') {
        item.material.aditional.aditionalDetail = item.material.aditional.aditionalDetail.map(
          (subItem: any) => {
            const hasDetailsInner = additionalDetail.some(
              (detail: any) => detail.id === subItem.id
            );
            return {
              ...subItem,
              hasBeenAdded: !!hasDetailsInner,
              isActiveToAddInApp: null,
            };
          }
        );
      }

      return item;
    });
  }


  // public async addToCart() {
  //   if (!this._user) {
  //     this.snackBar.open('Por favor, inicie sesión para agregar productos al carrito', '×', {
  //       panelClass: 'error',
  //       verticalPosition: 'top',
  //       duration: 5000,
  //     });

  //     setTimeout(() => {
  //       this.router.navigate(['/auth/ingresar']);
  //     }, 2000);
  //     return;
  //   }
  //   // Procesar y validar productos
  //   const productsToAdd = this.products.map((product, index) => {
  //       if (product.type === 'A') {
  //           if (this.aditional[index]?.aditionalDetail?.length > 0 && !this.onVerifiedQuantitySubnivel(this.aditionalSelected[index]))
  //             {
  //               this.snackBar.open('Por favor, completa las selecciones requeridas', '×', {
  //                   panelClass: 'error',
  //                   verticalPosition: 'top',
  //                   duration: 5000,
  //               });
  //               return null; // Producto inválido
  //           }
  //       }
  //       // Corregir como llega los aditional
  //       let additionalDetail: any[] = [];
  //       if (this.aditional[index]?.aditionalDetail?.length > 0) {
  //           this.aditionalSelected[index]?.selected?.forEach((item: any) => {
  //               if (!item.material?.type) {
  //                   additionalDetail.push(item);
  //               } else {
  //                   item.aditionalSelected?.selected?.forEach((subItem: any) => {
  //                       additionalDetail.push(subItem);
  //                   });
  //               }
  //           });
  //       }

  //       // Crear el producto auxiliar
  //       const auxProduct = {
  //           ...product,
  //           note: this.note,
  //           cartCount: 1,
  //       };

  //       if (product.type === 'A') {
  //           auxProduct.aditional.aditionalDetail = this.parseAdditional(
  //               this.aditional[index],
  //               additionalDetail
  //           );
  //           auxProduct.newPrice = this.getTotalItemSelected(product, this.aditionalSelected[index]?.selected || []);
  //       }

  //       return auxProduct;
  //   });

  //   const validProducts = productsToAdd.filter((product) => product !== null);

  //   if (validProducts.length === 0) {
  //       return; // Si no hay productos válidos, salir
  //   }
  //   // Enviar cada producto al carrito
  //   validProducts.forEach((product) => {
  //       product.isPromotion = true; // Marcar como promoción
  //       this.ecommerceService.addToCart(product); // Enviar producto al carrito
  //   });

  //   console.log("Productos enviados al carrito: ", validProducts);

  //   // Mostrar modal de confirmación
  //   const modalRef = this.modalService.open(ContinuarComprandoComponent);
  //   modalRef.componentInstance.itemCount = validProducts.length;
  // }
  // parseAdditional(aditional: any, additionalDetail: any) {
  //   console.log("products flujo promocion",this.products)
  //   console.log("aditional flujo promocion",this.aditional)
  //   console.log("aditionalSelected flujo promocion",this.aditionalSelected)
  //   console.log("note flujo promocion",this.note)

  //   return aditional.aditionalDetail.map((item: any) => {
  //     const hasDetails = additionalDetail.some((detail: any) => detail.id === item.id);
  //     return {
  //       ...item,
  //       hasBeenAdded: hasDetails,
  //       isActiveToAddInApp: null,
  //     };
  //   });
  // }
  // onVerifiedQuantitySubnivel(aditionalSelected: any) {
  //   console.log("Test onVerifiedQuantitySubnivel: ", aditionalSelected);

  //   // Usa `_selected` en lugar de `selected` y verifica si existe
  //   const selectedItems = aditionalSelected?._selected;
  //   if (!Array.isArray(selectedItems)) {
  //       console.error("aditionalSelected no tiene _selected iterable: ", aditionalSelected);
  //       return false; // Considerar como válido si no hay adicionales seleccionados
  //   }

  //   // Itera sobre los elementos seleccionados
  //   for (const item of selectedItems) {
  //       if (item.material?.type === 'A' && item.material.aditional?.quantity > 0) {
  //           const sum = item.aditionalSelected?._selected?.reduce(
  //               (acc: number, subItem: any) => acc + subItem.quantity,
  //               0
  //           );
  //           if (
  //               !(sum >= item.material.aditional.minQuantity &&
  //                 sum <= item.material.aditional.quantity)
  //           ) {
  //               return true; // Validación fallida
  //           }
  //       }
  //   }
  //   return false; // Validación exitosa
  // }
  // getTotalItemSelected(product: any, selected: any[]) {
  //   let total = Number(product?.newPrice || 0);
  //   if (product?.type === 'A') {
  //     selected.forEach((item: any) => {
  //       if (item.material.type === 'A') {
  //         item.aditionalSelected.selected.forEach((subItem: any) => {
  //           if (subItem.isAditionalPrice === '1') {
  //             total += Number(subItem.price) * subItem.quantity;
  //           }
  //         });
  //       }
  //     });
  //   }
  //   return total;
  // }
}
